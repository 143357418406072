import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../atoms/Image';
import Paginator from '../../molecules/Paginator';

const ArticleList = ({ articles, pagination }) => (
  <section>
    <ul className="flex flex-col gap-6 mb-10">
      {articles.map(article => (
        <li
          className="grid w-full sm:grid-cols-2 grid-rows-[min-content,1fr] gap-5 lg:gap-y-3 p-5 border border-gray-200 rounded lg:grid-cols-4"
          key={article.path}
        >
          {article.image && article.image.url && (
            <div className="col-span-1 row-span-2">
              <Image
                width={660}
                height={540}
                src={`${article.image.url}?w=660&h=540&fit=crop`}
                alt={article.image.alt}
                className="w-full"
              />
            </div>
          )}
          <div className="lg:col-span-3">
            <a className="block mb-2" href={article.path}>
              <h3>{article.title}</h3>
            </a>

            <div className="flex flex-col gap-0.5 text-sm text-gray-600 lg:items-center lg:flex-row lg:gap-0">
              <p>
                By{' '}
                {article.authors.map((author, index) => (
                  <a
                    key={author.slug}
                    href={`/authors/${author.slug}`}
                    className="text-gray-600 underline"
                  >
                    {author.name}
                    {index < article.authors.length - 1 ? ', ' : ''}
                  </a>
                ))}
              </p>

              <span
                className="hidden w-1 h-1 mx-2 align-middle rounded-full lg:inline-block bg-primary-200"
                aria-hidden="true"
              />

              <p>Posted on {article.published_at_formatted}</p>
            </div>
          </div>

          <div className="col-span-full lg:col-span-3">
            <p className="mb-2">{article.snippet}</p>

            <a href={article.path}>Read More</a>
          </div>
        </li>
      ))}
    </ul>

    {pagination && <Paginator pagination={pagination} />}
  </section>
);

ArticleList.defaultProps = {
  articles: [],
  pagination: null,
};

ArticleList.propTypes = {
  articles: PropTypes.array,
  pagination: PropTypes.object,
};

export default ArticleList;
