import { useEffect } from 'react';

const useGlobalTOCHandler = () => {
  const shouldUseHashHack = () => {
    if (typeof window !== 'undefined') {
      ////
      // Chrome Detection from:
      // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
      ////
      const isChromium = window.chrome;
      const winNav = window.navigator;
      const vendorName = winNav.vendor;
      const isOpera = typeof window.opr !== 'undefined';
      const isIEdge = winNav.userAgent.indexOf('Edge') > -1;
      const isIOSChrome = winNav.userAgent.match('CriOS');

      if (isIOSChrome) {
        // is Google Chrome on IOS
        // We return false though because chrome iOS does not currently
        // have the jump issues on chrome desktop
        return false;
      }

      if (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEdge === false
      ) {
        // is Google Chrome
        return true;
      }
    }
    // return false by default
    return false;
  };

  const handleTOCLinkClick = toHash => {
    if (typeof window !== 'undefined') {
      if (shouldUseHashHack()) {
        const swapHash = window.location.hash || toHash;
        window.location.hash = '';
        window.location.hash = swapHash;
      }
    }
    return true;
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.globalTOCClick = handleTOCLinkClick;
    }
  });
};

export default useGlobalTOCHandler;
