import React from 'react';
import PropTypes from 'prop-types';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import useGlobalTOCHandler from '../utils/useGlobalTOCHandler';
import Layout from '../components/layout/Layout';
import ArticleList from '../components/organisms/ArticleList';
import { TipsArticleSideBar } from '../components/organisms/ArticleSideBar';
import SEO from '../components/meta/SEO';

const CategoryTemplate = ({ pageContext }) => {
  useUserIPGrabber();
  useGlobalTOCHandler();

  const { articles, category, pagination, slug } = pageContext;

  return (
    <Layout>
      <SEO pathname={slug} title={category.title} />

      <div className="container py-12 lg:py-20 max-w-8xl">
        <h1 className="mb-6">Category: {category.title}</h1>

        <div className="grid gap-16 lg:gap-32 lg:grid-cols-4">
          <div className="lg:col-span-3">
            <ArticleList articles={articles} pagination={pagination} />
          </div>

          <TipsArticleSideBar currentPage={slug} />
        </div>
      </div>
    </Layout>
  );
};

CategoryTemplate.propTypes = {
  pageContext: PropTypes.any,
};

CategoryTemplate.defaultProps = {
  pageContext: {},
};

export default CategoryTemplate;
