import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../atoms/Icon';

const Paginator = ({ pagination }) => {
  const { current, total, last, next, prev } = pagination;

  return (
    <div className="grid grid-cols-[1fr,max-content,1fr] gap-3 mt-6">
      <div className="flex items-center justify-end">
        {prev && (
          <>
            <a className="flex items-center gap-2" href={prev}>
              <Icon type="arrowLeft" className="w-3" />
              Newer Posts
            </a>
            <span
              className="inline-block w-1 h-1 ml-3 align-middle rounded-full  bg-primary-200"
              aria-hidden="true"
            />
          </>
        )}
      </div>

      <p>
        Page {current} of <a href={last}>{total}</a>
      </p>

      <div className="flex items-center">
        {next && (
          <>
            <span
              className="inline-block w-1 h-1 mr-3 align-middle rounded-full  bg-primary-200"
              aria-hidden="true"
            />
            <a className="flex items-center gap-2" href={next}>
              Older Posts
              <Icon type="arrowRight" className="w-3" />
            </a>
          </>
        )}
      </div>
    </div>
  );
};

Paginator.propTypes = {
  pagination: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
    last: PropTypes.string,
    next: PropTypes.string,
    prev: PropTypes.string,
  }).isRequired,
};

export default Paginator;
